import React from 'react';

import Dashboard from './Dashboard/Dashboard';

const Home = () => {
  // const { isLoggedIn, setIsLoggedIn } = useAuth();

  return (
    <div>
        <Dashboard />
    </div>
  );
};

export default Home;
